import { WebApp } from '@grammyjs/web-app'
import { useEffect, useState } from 'react'

import { apiService } from '../services/api/api.service'

import useRefresh from './useRefresh'

export const useTgWebApp = () => {
  return WebApp
}

export const useDomains = (filter = {}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      try {
        const domains = await apiService.getAllDomains(filter)
        setData(domains)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }

    fetch()
  }, [slowRefresh, filter])
  return {
    loading,
    data,
  }
}

export const useConnectedWallet = () => {
  return {
    address: '...',
  }
}
