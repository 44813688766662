import React from 'react'
import cx from 'classnames'

import './Card.css'

const Card = ({ className, children }) => {
  const classNames = cx('card', className)
  return <div className={classNames}>{children}</div>
}

export default Card
