import React, { useEffect } from 'react'

import { useTgWebApp } from './hooks'

import UserCard from './components/UserCard'
import Domains from './components/Domains'

import './App.css'
import { RefreshContextProvider } from './hooks/useRefresh'

function App() {
  const tgWebApp = useTgWebApp()
  useEffect(() => {
    tgWebApp.ready()
    tgWebApp.expand()
  }, [tgWebApp])
  return (
    <RefreshContextProvider>
      <div className="app">
        <UserCard />
        <Domains />
      </div>
    </RefreshContextProvider>
  )
}

export default App
