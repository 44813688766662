import React from 'react'
import cx from 'classnames'

import './Loader.css'

const Loader = ({ size = 'md', className }) => {
  const classNames = cx(
    'loader',
    {
      [`loader--${size}`]: size,
    },
    className,
  )
  return (
    <div className="loader-wrapper">
      <div className={classNames} />
    </div>
  )
}

export default Loader
