import React from 'react'

import { shortAddress } from '../../utils'

import { useTgWebApp, useConnectedWallet } from '../../hooks'

import Card from '../Card'

import './UserCard.css'

const useTgUser = () => {
  const tgWebApp = useTgWebApp()
  const connectedWallet = useConnectedWallet()

  const mockUser =
    process.env.NODE_ENV === 'production'
      ? {}
      : {
          first_name: 'A',
          last_name: 'B',
          username: 'ab',
          language_code: 'ru',
          id: 123,
        }

  return {
    ...(tgWebApp.initDataUnsafe.user || mockUser),
    connectedWallet,
  }
}

const UserCard = () => {
  const { first_name, last_name, username, connectedWallet } = useTgUser()
  if (!username) return null
  return (
    <Card className="user-card">
      <div className="user-card__info">
        <div className="user-card__username">@{username}</div>
        <div className="user-card__fullname">
          {first_name} {last_name}
        </div>
      </div>
      <div className="user-card__wallet">
        {shortAddress(connectedWallet.address)}
      </div>
    </Card>
  )
}

export default UserCard
