import React from 'react';
import cx from 'classnames';

import './Hint.css';

const Hint = ({className, children}) => {
    const classNames = cx('hint', className);
    return (
        <div className={classNames}>
            {children}
        </div>
    )
}

export default Hint;