import React, { useMemo, useEffect } from 'react'

import Hint from '../Hint'

import './Input.css'

const Input = ({ name, value, onChange, appendix, hint, placeholder }) => {
  const handleInput = (e) => {
    const { value: newValue } = e.target
    onChange({ name, value: newValue })
  }

  return (
    <div className="input-item">
      <div className="input-wrapper">
        <input
          placeholder={placeholder}
          name={name}
          value={value}
          className="input"
          onChange={handleInput}
        />
        {appendix && <div className="input-appendix">{appendix}</div>}
      </div>
      {hint && <Hint>{hint}</Hint>}
    </div>
  )
}

export default Input
