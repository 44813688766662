import React from 'react'
import cx from 'classnames'

import './Link.css'

const Link = ({ href, className, children, target, onClick }) => {
  const classNames = cx('link', className)
  return (
    <a href={href} className={classNames} target={target} onClick={onClick}>
      {children}
    </a>
  )
}

export default Link
