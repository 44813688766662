import React from 'react'
import {
  AiOutlineLink,
  AiOutlineNotification,
  AiOutlineDollarCircle,
} from 'react-icons/ai'
import moment from 'moment'

import { shortAddress } from '../../utils'

import Loader from '../Loader'
import Hint from '../Hint'
import Link from '../Link'
import Card from '../Card'

import './DomainsList.css'
import { useTgWebApp } from '../../hooks'

const iconSize = 18

const DomainsListItem = ({
  domain,
  maxBitAddress,
  maxBitAmount,
  aucIsActive,
  aucEndTs,
  nftAddress,
}) => {
  const tgWebApp = useTgWebApp()
  const hasBidder = !!maxBitAddress && aucIsActive
  const hasOwner = !!maxBitAddress && !aucIsActive

  const handleSubscribeClick = () => {
    tgWebApp.sendData(`${domain} subscribe`)
  }

  const handleBuyClick = () => {
    tgWebApp.sendData(`${domain} buy`)
  }
  return (
    <Card className="domains-list__item domain-card">
      <div className="domain-card__header">
        <div className="domains-list__item-domain">
          {domain}
          <span>.ton</span>
        </div>
        <div className="domain-card__tools">
          <Link
            href={`https://dns.ton.org/#${domain}`}
            className="domain-link domain-card__tool"
            target="_blank"
            title="Open domain page"
          >
            <AiOutlineLink size={iconSize} />
          </Link>

          <Link
            className="domain-link domain-link--disabled domain-card__tool"
            target="_blank"
            title="Subscribe on domain"
            onClick={handleSubscribeClick}
          >
            <AiOutlineNotification size={iconSize} />
          </Link>

          <Link
            className="domain-link domain-link--disabled domain-card__tool"
            target="_blank"
            title="Buy domain"
            onClick={handleBuyClick}
          >
            <AiOutlineDollarCircle size={iconSize} />
          </Link>
        </div>
      </div>
      <div className="domains-list__item-owner">
        <Hint>NFT Address: </Hint>
        <Link
          href={`https://tonscan.org/address/${nftAddress}`}
          target="_blank"
        >
          {shortAddress(nftAddress, 6)}
        </Link>
      </div>
      {hasOwner && (
        <>
          <div className="domains-list__item-owner">
            <Hint>Owner: </Hint>
            <Link
              href={`https://tonscan.org/address/${maxBitAddress}`}
              target="_blank"
            >
              {shortAddress(maxBitAddress, 6)}
            </Link>
          </div>
          <div className="domains-list__item-price">
            <Hint>Paid: </Hint>
            <div>{maxBitAmount} TON</div>
          </div>
        </>
      )}
      {/* <div className="domains-list__item-status">
        <Hint>Auction status: </Hint>
        <div>{getDomainStatus(aucStatus)}</div>
      </div> */}
      {hasBidder && (
        <>
          <div className="domains-list__item-last-bidder">
            <Hint>Last bidder: </Hint>
            <Link href={`https://tonscan.org/address/${maxBitAddress}`}>
              {shortAddress(maxBitAddress, 6)}
            </Link>
          </div>
          <div className="domains-list__item-last-bid">
            <Hint>Last bid: </Hint>
            <div>{maxBitAmount} TON</div>
          </div>
          {aucIsActive && (
            <div className="domains-list__item-till-end">
              <Hint>Ends in:</Hint>
              <div>{moment(parseInt(aucEndTs), 'X').fromNow()}</div>
            </div>
          )}
        </>
      )}
      {/* <div className="domains-list__item-status">
        <Hint>Domain status: </Hint>
        {hasBidder ? 'Auction' : hasOwner ? 'Busy' : 'Free'}
      </div> */}
    </Card>
  )
}

const DomainsList = ({ data, loading, title }) => {
  const showLoader = !data.length && loading
  return (
    <div className="domains-list">
      {title && <div className="domains-list__title">{title}</div>}
      {!data.length && <div className="domains-list__no-data">No Data</div>}
      {data.map((item, index) => (
        <DomainsListItem key={index} {...item} />
      ))}
      {showLoader && <Loader />}
    </div>
  )
}

export default DomainsList
