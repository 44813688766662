import React, { useState, useMemo } from 'react'
import cx from 'classnames'

import { useDomains, useConnectedWallet } from '../../hooks'

import DomainsList from '../DomainsList'
import SearchDomain from '../SearchDomain'

import './Domains.css'

const TABS = {
  AUCTIONS: {
    key: 'auctions',
    title: 'Active auctions',
  },
  LATEST: {
    key: 'latest',
    title: 'Finished auctions',
  },
  MY_DOMAINS: {
    key: 'my_domains',
    title: 'My domains',
  },
}

const DomainsTab = ({ active, onClick, children }) => {
  const classNames = cx('domains-tab', { 'domains-tab--active': active })
  return (
    <div className={classNames} onClick={onClick}>
      {children}
    </div>
  )
}

const DomainsTabs = ({ tabs, onSelect, activeKey }) => {
  return (
    <div className="domains-tabs">
      {Object.keys(tabs).map((tabKey, index) => {
        const tab = tabs[tabKey]
        const onClick = () => onSelect(tab.key)
        const isTabActive = tab.key === activeKey
        return (
          <DomainsTab key={index} onClick={onClick} active={isTabActive}>
            {tab.title}
          </DomainsTab>
        )
      })}
    </div>
  )
}

const domainsFilter = {
  limit: 10,
}

const auctionDomainsFilter = { limit: 100, onlyActive: true }

const Domains = () => {
  const [activeTab, setActiveTab] = useState(TABS.AUCTIONS.key)
  const connectedWallet = useConnectedWallet()
  const myDomainsFilter = useMemo(
    () => ({
      limit: 100,
      userAddress: connectedWallet.address,
    }),
    [connectedWallet.address],
  )
  const domains = useDomains(domainsFilter)
  const auctionDomains = useDomains(auctionDomainsFilter)
  const myDomains = useDomains(myDomainsFilter)
  let domainsData = myDomains
  if (activeTab === TABS.AUCTIONS.key) {
    domainsData = auctionDomains
  } else if (activeTab === TABS.LATEST.key) {
    domainsData = domains
  }
  const onSelectTab = (key) => setActiveTab(key)
  return (
    <>
      <SearchDomain />
      <DomainsTabs tabs={TABS} onSelect={onSelectTab} activeKey={activeTab} />
      <DomainsList {...domainsData} />
    </>
  )
}

export default Domains
