import React, { useEffect, useState, useMemo } from 'react'
import { AiOutlineLink } from 'react-icons/ai'
import debounce from 'lodash.debounce'

import { shortAddress } from '../../utils'

import { apiService } from '../../services/api/api.service'

import Card from '../Card'
import Input from '../Input'
import Link from '../Link'
import Hint from '../Hint'
import Loader from '../Loader'

import './SearchDomain.css'

const iconSize = 18

const SearchDomain = () => {
  const [domainQuery, setDomainQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [domain, setDomain] = useState(null)
  const handleChange = ({ value }) => {
    setDomainQuery(value.toLowerCase().trim())
  }

  const fetch = async (query) => {
    console.log('QUERY', query)
    if (query.length >= 4) {
      setDomain(null)
      setLoading(true)
      try {
        const info = await apiService.getDomainInfo(query)
        setDomain(info)
      } catch (e) {
        setDomain(null)
        console.error(e)
      } finally {
        setLoading(false)
      }
    } else {
      setDomain(null)
    }
  }

  const debouncedFetch = useMemo(() => debounce(fetch, 300), [])

  useEffect(() => {
    console.log('QUERY', domainQuery)
    debouncedFetch(domainQuery)
  }, [domainQuery])

  useEffect(() => {
    return () => {
      debouncedFetch.cancel()
    }
  }, [])

  const isActive = domain && domain.domainExists

  const hasOwner = !!(isActive && domain.ownerAddress)

  return (
    <div className="search-domain">
      <div>
        <Input
          placeholder="Enter your brand new domain"
          name="domain"
          value={domainQuery}
          onChange={handleChange}
          appendix=".ton"
          hint="From 4 letters"
        />
      </div>
      {loading ? (
        <Loader />
      ) : domain ? (
        <Card className="search-result-card">
          <div className="domain-card__header">
            <div className="domains-list__item-domain">
              {domainQuery}
              <span>.ton</span>
            </div>
            <div className="domain-card__tools">
              <Link
                href={`https://dns.ton.org/#${domainQuery}`}
                className="domain-link domain-card__tool"
                target="_blank"
                title="Open domain page"
              >
                <AiOutlineLink size={iconSize} />
              </Link>
            </div>
          </div>
          {isActive && (
            <div className="domains-list__item-owner">
              <Hint>NFT Address: </Hint>
              <Link
                href={`https://tonscan.org/address/${domain.nftAddress}`}
                target="_blank"
              >
                {shortAddress(domain.nftAddress, 6)}
              </Link>
            </div>
          )}
          {hasOwner && (
            <>
              <div className="domains-list__item-owner">
                <Hint>Owner: </Hint>
                <Link
                  href={`https://tonscan.org/address/${domain.ownerAddress}`}
                  target="_blank"
                >
                  {shortAddress(domain.ownerAddress, 6)}
                </Link>
              </div>
            </>
          )}

          <div className="domains-list__item-status">
            <Hint>Domain status: </Hint>
            {hasOwner ? 'Busy' : 'Free'}
          </div>
        </Card>
      ) : null}
    </div>
  )
}

export default SearchDomain
