import axios from 'axios'

import { API_URL } from '../../config'
class ApiService {
  constructor(baseUrl) {
    this.api = axios.create({
      baseURL: baseUrl,
    })
  }

  getMyDomains = async (address) => {
    return await this.api.get(`/dns`, {
      params: {
        userAddress: address,
      },
    })
  }

  getAllDomains = async (fitler) => {
    const { data } = await this.api.get('/dns', {
      params: {
        limit: fitler.limit,
        onlyActive: fitler.onlyActive,
        userAddress: fitler.userAddress,
      },
    })
    return data.map((item) => {
      return {
        ...item,
        domain: item.domain.toLowerCase(),
      }
    })
  }

  getDomainInfo = async (domain) => {
    const { data } = await this.api.get(`/dns/check/${encodeURI(domain)}`)
    return data
  }
}

export const apiService = new ApiService(API_URL)

export default ApiService
